<template>
  <div>
    <div
      class="row shadow-sm"
      style="z-index: 1"
      v-if="activeAlarms.length > 0"
    >
      <div class="mt-3 pb-2 mb-2 border-bottom border-primary">
        <strong>{{$t('system.activeAlarms')}}</strong>
      </div>

      <div
        v-for="alarm in activeAlarms"
        :key="alarm"
        class="py-2 border-bottom"
      >
        <strong>{{ alarm.name }}</strong
        ><br />
        <div class="row">
          <div class="col-sm">
            <small>
              <span class="text-muted">{{alarm.deviceName}}</span>
            </small>
          </div>
          <div class="col-sm text-end">
            <small
              ><span class="text-muted">{{
                $filters.formatDate(alarm.timestamp, "yyyy-MM-DD")
              }}</span>
              <span class="text-primary">&nbsp;&nbsp;|&nbsp;&nbsp;</span
              ><span class="text-muted">{{
                $filters.formatDate(alarm.timestamp, "HH:mm:ss")
              }}</span></small
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-light flex-grow-1" v-if="historyAlarms.length > 0">
      <div class="mt-3 pb-2 mb-2 border-bottom border-primary">
        <strong>{{$t('system.alarmHistory')}}</strong>
      </div>
      <div
        v-for="alarm in historyAlarms"
        :key="alarm"
        class="py-2 border-bottom"
      >
        <strong>{{ alarm.name }}</strong
        ><br />

        <div class="row">
          <div class="col-sm">
            <small>
              <span class="text-muted">{{alarm.deviceName}}</span>
            </small>
          </div>
          <div class="col-sm text-end">
            <small
              ><span class="text-muted">{{
                $filters.formatDate(alarm.timestamp, "yyyy-MM-DD")
              }}</span>
              <span class="text-primary">&nbsp;&nbsp;|&nbsp;&nbsp;</span
              ><span class="text-muted">{{
                $filters.formatDate(alarm.timestamp, "HH:mm:ss")
              }}</span></small
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="row flex-grow-1"
      v-if="activeAlarms.length == 0 && historyAlarms.length == 0 && !loading"
    >
      <div class="mt-5 pb-2 mb-2 text-center">
        <span class="text-muted">{{$t('system.noAlarms')}}</span>
      </div>
    </div>
  </div>
  <!-- <pre>{{ alarms }}</pre> -->
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Systems from "../api/systems";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const systemId = route.params.systemId;
    const activeAlarms = ref([]);
    const historyAlarms = ref([]);
    const loading = ref(true);

    onMounted(() => {
      store.commit("setShowBackButton", true);
      Systems.getAlarms(systemId)
        .then((res) => {
          activeAlarms.value = res.data.active;
          historyAlarms.value = res.data.history;
          loading.value = false;
        })
        .catch((err) => {
          console.log(err);
        });
    });

    return { activeAlarms, historyAlarms, loading };
  },
};
</script>

<style>
</style>