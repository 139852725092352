import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const apiUrl = process.env.VUE_APP_API_URL;

export default {
    getById(id) {
        return axios.get(`${apiUrl}/api/system/${id}`);
    },
    sync(id) {
        return axios.get(`${apiUrl}/api/system/${id}/sync`);
    },
    turnOn(id) {
        return axios.post(`${apiUrl}/api/system/${id}/on`);
    },
    turnOff(id) {
        return axios.post(`${apiUrl}/api/system/${id}/off`);
    },
    reset(id) {
        return axios.post(`${apiUrl}/api/system/${id}/reset`);
    },
    changePressure(id, pressure) {
        return axios.post(`${apiUrl}/api/system/${id}/pressure/${pressure}`);
    },
    changeLevel(id, level) {
        return axios.post(`${apiUrl}/api/system/${id}/level/${level}`);
    },
    changeSpeed(id, speed) {
        return axios.post(`${apiUrl}/api/system/${id}/speed/${speed}`);
    },
    toggleSubPump(id, pumpNumber) {
        return axios.post(`${apiUrl}/api/system/${id}/pump/${pumpNumber}`);
    },
    getAlarms(id) {
        return axios.get(`${apiUrl}/api/system/${id}/alarms`);
    },
    getSmsLog(id) {
        return axios.get(`${apiUrl}/api/system/${id}/sms-log`);
    }
};
