<template>
  <div class="row align-items-center">
    <div class="col-3">
      <div v-if="showBackButton">
        <button class="btn btn-link text-dark px-0" @click="$router.go(-1)">
          <i class="fal fa-chevron-left fa-lg"></i>
        </button>
      </div>
      <div v-else>
        <button class="btn btn-link text-dark px-0">
          <i class="fal fa-bars fa-lg" data-bs-toggle="offcanvas" data-bs-target="#menu"></i>
        </button>
      </div>
    </div>
    <div class="col">
      <div class="text-center">
        <img src="../assets/images/logo.svg" alt="Logo" style="width: 100px" />
      </div>
    </div>
    <div class="col-3 text-end">
      <div id="headerAction"></div>
    </div>
  </div>
</template>


<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const showBackButton = computed(() => {
      return store.state.showBackButton;
    });

    return { showBackButton };
  },
};
</script>