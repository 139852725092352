import axios from "axios";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const apiUrl = process.env.VUE_APP_API_URL;

export default {
    getAll() {
        return axios.get(`${apiUrl}/api/projects`);
    },
    getById(id) {
        return axios.get(`${apiUrl}/api/projects/${id}`);
    },
    getByIdLive(id) {
        return axios.get(`${apiUrl}/api/projects/${id}/live`);
    }
};
