<template>
  <transition name="fade">
    <div class="loading" v-if="showModal">
      <div class="loading__content">
        <!-- <i class="fas fa-circle-notch fa-2x fa-spin text-primary"></i><br> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="100px"
          height="100px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="84" cy="50" r="10" fill="#c3666a">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="0.3205128205128205s"
              calcMode="spline"
              keyTimes="0;1"
              values="9;0"
              keySplines="0 0.5 0.5 1"
              begin="0s"
            ></animate>
            <animate
              attributeName="fill"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="discrete"
              keyTimes="0;0.25;0.5;0.75;1"
              values="#c3666a;#cb9799;#c3666a;#b93c41;#c3666a"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="16" cy="50" r="10" fill="#c3666a">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;0;9;9;9"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="0s"
            ></animate>
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="50" cy="50" r="10" fill="#b93c41">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;0;9;9;9"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.3205128205128205s"
            ></animate>
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.3205128205128205s"
            ></animate>
          </circle>
          <circle cx="84" cy="50" r="10" fill="#c3666a">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;0;9;9;9"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.641025641025641s"
            ></animate>
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.641025641025641s"
            ></animate>
          </circle>
          <circle cx="16" cy="50" r="10" fill="#cb9799">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;0;9;9;9"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.9615384615384615s"
            ></animate>
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1.282051282051282s"
              calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1"
              values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
              begin="-0.9615384615384615s"
            ></animate>
          </circle>
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
import { watch, ref } from "vue";
export default {
  props: ["show"],
  setup(props) {
    const showModal = ref(false);

    watch(() => props.show, (value) => {
      if (value) {
        showModal.value = value;
        return;
      }

      setTimeout(() => {
        showModal.value = value;
      }, 1000);
    });

    return {showModal};
  }
};
</script>
<style>
</style>