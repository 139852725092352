import axios from "axios";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const apiUrl = process.env.VUE_APP_API_URL;

export default {
    login(email, password) {
        return axios.post(`${apiUrl}/api/account/login`, {
            "username": email,
            "password": password
        });
    },
    getCurrentMember() {
        return axios.get(`${apiUrl}/api/member/me`);
    },
    getCompleteProfileMember(key) {
        return axios.get(`${apiUrl}/api/member/complete?key=${key}`);
    },
    completeProfileMember(member) {
        return axios.post(`${apiUrl}/api/member/complete`, member);
    },
    requestResetPassword(email) {
        return axios.get(`${apiUrl}/api/member/reset-password?email=${email}&culture=${localStorage.language}`);
    },
    resetPassword(password, token) {
        return axios.post(`${apiUrl}/api/member/reset-password?password=${password}&token=${token}&culture=${localStorage.language}`);
    },
    updateProfile(member) {
        return axios.post(`${apiUrl}/api/member/me`, member);
    },
}