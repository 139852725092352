<template>
  <div class="mt-5 mb-5 text-center">{{$t('page.projectsPage.selectProject')}}</div>

  <div>
    <!-- <router-link to="/login"> -->
      <div v-for="project in projects" :key="project.id" class="project">
        <div>
          {{ project.name }}
        </div>
        <div>
          <i class="fal fa-chevron-right"></i>
        </div>
      </div>
    <!-- </router-link> -->
  </div>
  <!-- <button @click="logout">Logout</button><br /> -->
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Projects from "../api/projects";
export default {
  setup() {
    const store = useStore();
    const projects = ref([]);

    function logout() {
      localStorage.removeItem("accessToken");
      store.commit("setIsLoggedIn", false);
      store.commit("setAuthUser", {});
      // window.location.reload();
    }

    onMounted(() => {
      Projects.getProjects().then((res) => {
        projects.value = res.data;
      });
    });

    return { logout, projects };
  },
};
</script>

<style>
</style>