<template>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="menu">
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="close-menu"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            href="https://www.ostorpsbevattning.se/smartnet/"
            class="nav-link text-dark"
            target="_blank"
            >{{ $t("menu.information") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://www.ostorpsbevattning.se/smartnet/support-smartnet/"
            class="nav-link text-dark"
            target="_blank"
            >{{ $t("word.help") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://www.netbiter.net/"
            class="nav-link text-dark"
            target="_blank"
            >{{ $t("menu.oldSystemLogin") }}</a
          >
        </li>

        <li>
          <a
            class="nav-link text-dark"
            aria-current="page"
            href="#"
            @click="goTo('/profile')"
            >{{ $t("menu.changePassword") }}</a
          >
        </li>
      </ul>
      <hr />
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            class="nav-link text-dark text-muted"
            aria-current="page"
            href="#"
            @click="signOut"
            >{{ $t("menu.signOut") }}</a
          >
        </li>
        <li class="nav-item" v-if="deferredPrompt">
          <a
            class="nav-link text-dark text-muted"
            aria-current="page"
            href="#"
            @click="install"
            >{{ $t("menu.installSmartNet") }}</a
          >
        </li>
      </ul>
      <hr />
      <ul class="nav flex-column">
        <li class="nav-item">
          <p class="nav-link">
            <img
              src="../assets/images/flags/sweden.png"
              class="pointer"
              @click="selectLanguage('sv')"
            />&nbsp;
            <img
              src="../assets/images/flags/usa.png"
              class="pointer"
              @click="selectLanguage('en-US')"
            />&nbsp;
            <img
              src="../assets/images/flags/germany.png"
              class="pointer"
              @click="selectLanguage('de')"
            />&nbsp;
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import router from "../router";
import TranslationService from '../services/translationService';
export default {
  setup() {
    const store = useStore();
    const deferredPrompt = ref();

    function signOut() {
      localStorage.removeItem("accessToken");
      store.commit("setIsLoggedIn", false);
      store.commit("setAuthUser", {});

      document.getElementById("close-menu").click();

      router.push("/login");
      // var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
      //bsOffcanvas.close();
    }

    function goTo(path) {
      document.getElementById("close-menu").click();
      router.push(path);
    }

    onMounted(() => {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt.value = e;
      });
      window.addEventListener("appinstalled", () => {
        deferredPrompt.value = null;
      });
    });

    function install() {
      deferredPrompt.value.prompt();
    }

    function selectLanguage(language) {
      TranslationService.getAndSetLanguage(language);
    }

    return { signOut, goTo, install, deferredPrompt, selectLanguage };
  },
};
</script>

<style>
</style>