<template>
  <div class="mt-5 text-center">
    <div class="system-state system-state--lg">
      <!-- <img v-bind:src="'/assets/images/system/pump/' + system.state + '.svg'" class="circle" /> -->
      
      <img
        :src="require(`@/assets/images/system/pump/pump-${system.state}.svg`)"
        v-if="system.type == 5"
      />
      <img
        :src="require(`@/assets/images/system/machine-${system.state}.svg`)"
        v-if="system.type == 20"
      />

      <div class="system-state__secondary-state" v-if="system.hand == '1'">
        <img src="../../../assets/images/icons/hand.svg" />
      </div>      
    </div>
    
    <h1 class="text-center h2 mt-3">{{ system.name }}</h1>
    <div>
      <strong class="text-uppercase">
        <small>{{ system.project.name }}</small>
      </strong>
      <br />
      <small class="text-muted text-uppercase"
        >{{ $filters.formatDate(system.updateDate) }}
      </small>
    </div>
    <div class="alert alert-danger my-4" role="alert" v-if="system.state == 40 || system.state == 50">
      <span v-if="system.state == 40">{{ $t("system.noContact") }} {{ system.name }}</span>
      <span v-if="system.state == 50">{{ system.name }} {{ $t("system.isOffline") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["system"]
};
</script>

<style>
</style>