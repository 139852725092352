<template>
  <transition name="fade">
    <div class="splash" v-if="init">
      <div class="splash__content">       
        <SplashComponent />
      </div>
    </div>
  </transition>
  <div class="container border pt-4 main">
    <Header v-if="isLoggedIn" />
    <LoadingModal :show="init" />
    <router-view></router-view>
    <!-- <router-view v-slot="{ Component }">
      <transition name="slide" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view> -->

    <Footer />
  </div>

  <Menu v-show="isLoggedIn" />
</template>

<script>
import { onMounted, computed, watch, ref } from "vue";
import Members from "./api/members";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Menu from "./components/Menu";
import { useStore } from "vuex";
import router from "./router";
import "bootstrap";
import LoadingModal from "./components/LoadingModal";
import TranslationService from "./services/translationService";
import SplashComponent from "./components/Splash"
export default {
  components: { Footer, Header, Menu, LoadingModal, SplashComponent },
  setup() {
    const store = useStore();
    const init = ref(true);
    const startTime = ref(Date.now());

    onMounted(() => {
      
      // const svgatorObject = document.getElementById('animated-svg');
      // const svgatorDocument = svgatorObject.contentDocument || svgatorObject.contentWindow.document;
      // const svgatorElement = svgatorDocument.getElementById('esIPouFKiWN1');
      // svgatorElement?.svgatorPlayer?.play();
      // svgatorElement?.svgatorPlayer?.ready(player => player.play());
      startTime.value = Date.now();
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("qlt")) {
        let token = urlParams.get("qlt");
        localStorage.accessToken = token;
      }

      if (!localStorage.accessToken) {
        //router.push("/login");
      } else {
        Members.getCurrentMember()
          .then((res) => {
            if (res.data) {
              store.commit("setIsLoggedIn", true);
              store.commit("setAuthUser", res.data);
            } else {
              store.commit("setIsLoggedIn", false);
              store.commit("setAuthUser", {});
            }
          })
          .catch(() => {
            store.commit("setIsLoggedIn", false);
            store.commit("setAuthUser", {});
          });
      }

      const userLocale = localStorage.language || "sv"; //navigator.language || 'en';
      TranslationService.getAndSetLanguage(userLocale, () => {
        //console.log("körs")

        const elapsedTime = Date.now() - startTime.value;
        const remainingTime = Math.max(3000 - elapsedTime, 0);
        setTimeout(() => {
          init.value = false;
        }, remainingTime);
        //
      });
    });

    const isLoggedIn = computed(() => {
      return store.state.isLoggedIn;
    });

    watch(isLoggedIn, (loggedIn) => {
      if (!loggedIn) {
        router.push("/login");
      }
    });

    const loading = computed(() => {
      return store.state.loading;
    });

    return { loading, isLoggedIn, init };
  },
};
</script>