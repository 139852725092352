<template>
  <Ssp3 v-if="system.type == 5 && system.argosType == 10" :current-system="system" />
  <Slr v-if="system.type == 5 && system.argosType == 20" :current-system="system" />
  <Smp v-if="system.type == 5 && system.argosType == 30" :current-system="system" />
  <FasterholtSms v-if="system.type == 20" :current-system="system" />
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Systems from "../api/systems";
import Ssp3 from "../components/Systems/Ssp3";
import Slr from "../components/Systems/Slr";
import Smp from "../components/Systems/Smp";
import FasterholtSms from "../components/Systems/FasterholtSms";

export default {
  components: { Ssp3, FasterholtSms, Slr, Smp },
  setup(_, { emit }) {
    const store = useStore();
    const system = ref({});
    const route = useRoute();
    const systemId = route.params.systemId;

    onMounted(() => {
      store.commit("setShowBackButton", true);
      emit("show-back-button");
      Systems.getById(systemId).then((res) => {
        system.value = res.data;
      });
    });

    return { system };
  },
};
</script>

<style>
</style>