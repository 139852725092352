import Translations from '../api/translations';
import i18n from '../i18n';

export default class TranslationService {
    static async getAndSetLanguage(locale, callback) {
        if(!this.isValid(locale))
            return;

        Translations.getTranslations(locale).then((response) => {
            localStorage.language = locale;
            
            i18n.global.setLocaleMessage(locale, response.data);
            i18n.global.locale = locale; // Update the locale

            if(callback) {
                callback();
            }            
        })
        .catch(() => {

        })
    }

    static isValid(culture) {
        return culture == "sv" || culture == "en-US" || culture == "de";            
    }
}