export default class ErrorResponse {
    Error = null;

    constructor(error) {
        this.Error = error;
    }

    getMessage() {
        if (!this.Error || !this.Error.response || !this.Error.response.data)
            return null;

        if (typeof this.Error.response.data === 'object')
            return this.Error.response.data.message;

        return this.Error.response.data;
    }
}