<template>
  <div class="modal fade" id="commandModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-sm btn-rounded"
            data-bs-dismiss="modal"
          >
            {{$t('button.cancel')}}
          </button>

          <slot name="action"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
